@use "../mixins/mq";
@use "../atoms/grid";

.module__content {
	position: relative;
	padding: 4rem 2rem;
	background-color: var(--color-primary-3);

	@include mq.mq($from: large) {
		padding: 6rem;
	}
}

.module__content--has-image {
	margin-top: 8rem;
	padding-top: 8rem;

	@include mq.mq($from: large) {
		padding-top: 10rem;
	}
}


.c-quote__text {
	margin-top: 4rem;
}

.c-quote__blockquote {
	padding: 0;
	border: 0;
}

.c-quote__figcaption {
	margin-top: 2rem;

	@include mq.mq($from: large) {
		margin-top: 4rem;
	}

	strong {
		display: block;
	}
}
