@use "../mixins/mq";

.form .errors,
.errors {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  line-height: 3rem;

  &.alert {
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
  }

  &.invalid-feedback {
    list-style: none;
    color: var(--color-error);
  }

  li {
    padding-left: 0;
    text-indent: 0;

    &:not(:first-child) {
      margin-top: var(--spacing-10);
    }
  }
  li:before {
    display: none;
  }
}