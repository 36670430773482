@use "../mixins/mq";
@use "../_base/typography";

.modular .nav--meta-nav {
	margin-top: 0;
	font-size: 1.7rem;
	@extend .font-weight-400;

	@include mq.mq($from: large) {
		margin-top: 0;
		font-size: 1.6rem;
	}

	.nav__item--parent {
		width: 100%;
		margin: 0.8rem var(--spacing-20);

		@include mq.mq($from: large) {
			width: auto;
			margin-left: var(--spacing-40);
			margin-right: 0;
		}
	}

	.nav__link {
		display: inline-flex;
		padding: 0;
		line-height: 2.5rem;

		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}
	}

	.nav__link[aria-expanded=true] {
		color: var(--color-grey-400);
		opacity: 0.4;
	}
}
