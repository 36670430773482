@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.field__row {

	* {
		font-family: inherit
	}

}

.field-group {
	position: relative;
	margin-top: 5rem;

	&--half {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(100% - 3rem);
		}
	}

	&--third {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(100% - 3rem);
		}
	}

	&--large {
		width: 100%;
	}

	&--medium {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(4, 6, 6rem);
		}
	}

	&--small {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(2, 6, 6rem);
		}
	}

	&--search {
		margin-top: 0;
		border-bottom: 1px solid var(--color-grey-400);
	}

	.row & {
		& + .field-group {

			@include mq.mq($from: large) {
				margin-left: 6rem;
			}
		}
	}

	&--bordered {
		border-bottom: 1px solid var(--color-black);

		&.field-group--has-errors {
			border-bottom: 2px solid var(--color-error);

			&:before {
				position: absolute;
				left: -7px;
				right: -7px;
				bottom: 0;
				height: 100%;
				display: block;
				content: '';
				background-color: #F21C2810;
				z-index: 0;
			}
		}
	}

	&--checkbox {
		margin-top: var(--field-checkbox-margin-top, 6rem);
	}

	&--html {
		margin-top: var(--spacingWide);
		color: var(--color-black);

		@include mq.mq($from: large) {
			margin-top: 10rem;
		}
	}

	&--block {
		margin-top: 6rem;
	}
}


.field__input {
	position: relative;
	display: block;
	padding: 0;
	min-height: 4rem;
	max-width: 100%;
	border: 0;
	width: 100%;
	outline: none;
	background: none;
	@extend .font-size-400;

	&--file {
		width: auto;
		min-height: unset;
	}

	&--search {
		padding-left: 3.5rem;
		padding-bottom: 0.8rem;
		font-size: 1.6rem;
	}
}

.field__label {
	position: relative;
	display: block;
	color: var(--color-black, inherit);
	text-align: var(--label-align, inherit);
	font-size: 1.3rem;
	border-bottom: 0;
	font-weight: 400;
	margin-bottom: 0;

	.field-group--block & {
		position: absolute;
		left: 0;
		top: 0.2rem;
		width: 100%;
		transition: all var(--animationDuration) var(--animationEase);
		cursor: pointer;
	}

	.field-group--block-animated & {
		font-size: 1.7rem;
		line-height: 2rem;
		color: var(--color-grey-200);
		@extend .font-weight-400;
		transform: translateY(-2.7rem);
	}

	.field-group--textarea.field-group--block-animated & {
		transform: translateY(-3.5rem);
	}

	&.required {
		&:after {
			content: '*';
		}
	}
}


.field__instructions {
	--paragraph-font-size: var(--global-font-size)
}

//input[type=checkbox],
.field__input--check,
.field__input--radio {
	@extend .visually-hidden;
}


.field__sub-label {
	position: relative;
	display: block;
	padding-top: 0.5rem;
	margin-top: var(--label-margin-top, 1rem);
	@extend .font-size-400;
	padding-left: 6rem;
	min-height: 4rem;
	cursor: pointer;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 4rem;
		height: 4rem;
		content: '';
		border: 1px solid var(--color-black);
	}

	.field__input--radio + & {
		&:before {
			border-radius: 50%;
		}
	}

	input[type=checkbox]:checked + &,
	.field__input--check:checked + & {
		&:before {
			@include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, 20px, $fillColor: colors.$color-primary-1);
		}
	}

	.field__input--radio:checked + & {
		&:after {
			position: absolute;
			left: .3rem;
			top: .3rem;
			display: block;
			content: '';
			width: 3.4rem;
			height: 3.4rem;
			background-color: var(--color-primary-1);
			border-radius: 50%;
		}
	}

	&--inline {
		display: inline-flex;
		margin-right: 4rem;
	}
}

.field__input--textarea {
	transition: height var(--animationDuration) var(--animationEase);
}

/*.ff-form-errors,
.ff-form-success {
	margin-bottom: 6rem;
	@extend .font-size-700;
	@extend .font-weight-700;
}

.ff-form-success {
	color: var(--color-success);
}

.ff-form-errors {
	color: var(--color-error);
}


.ff-errors {
	position: absolute;
	top: 100%;
	font-size: 1.6rem;
	line-height: 3.2rem;
	letter-spacing: 0.1rem;
	@extend .font-weight-500;
	color: var(--color-error);

	:is(.field-group--checkbox_group) + & {
		position: relative;
		top: auto;
		margin-top: var(--spacing-10);
	}
}*/

.field-group--submit {
	.action + .action {
		margin-left: 2rem;
	}
}


[disabled]+.field__sub-label {
    opacity: .5;
    pointer-events: none
}