@use "../mixins/mq";
@use "../_base/typography";

.modular .lang-chooser {
	margin-top: var(--spacing-50);
	margin-left: var(--spacing-20);
	@extend .font-weight-400;

	@include mq.mq($from: large) {
		margin-top: 0;
		margin-left: 0;
	}
}

.modular .lang-chooser__item {
	position: relative;
	font-size: 1.6rem;

	&:not(:first-child) {
		padding-left: var(--spacing-10);

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: var(--spacing-10);
			text-align: center;
			content: '|';
			line-height: normal;

			@include mq.mq($from: large) {
				top: 1px;
			}
		}
	}

	.nav__link {
		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}
	}
}

.lang-chooser__item--is-active {
	color: var(--color-grey-400);
	opacity: 0.4;
}
