.hamburger {
  position: relative;
  display: block;
  width: var(--hamburgerWidth);
  height: var(--hamburgerWidth);
  padding: 0;
  overflow: hidden;
  color: currentColor;
  transform: translateZ(0);
}

.hamburger__text {
  display: none;
}

.hamburger__line {
  position: absolute;
  top: 50%;
  right: 0;

  display: block;
  height: var(--hamburgerLineHeight);
  width: calc(var(--hamburgerWidth) / 1);
  background-color: currentColor;
  border-radius: 2px;

  transition: transform .3s ease;
  transform-origin: 50% 50%;

  &:first-child,
  &:last-child {
    width: var(--hamburgerWidth);
  }

  &:first-child {
    transform: rotate(180deg) translateY(6px);
  }

  &:last-child {
    transform: translateY(6px);
  }
}

.hamburger__inner {
  position: relative;
  display: block;
  height: 16px;
  width: 100%;
}

.hamburger[aria-expanded="true"] {
  background-color: var(--color-white);
  .hamburger__line {

    transform: translateX(150%);
    opacity: 0;

    &:last-child {
      transform: rotate(45deg);
      opacity: 1;
    }

    &:first-child {
      transform: rotate(-45deg);
      opacity: 1;
    }

  }

}
