@use "../mixins/mq";

.c-imageText .section__title {
	margin-top: var(--spacing-40);
}

.c-imageText-item__text {
	padding-top: var(--spacing-40);

	@include mq.mq($from: large) {
		//padding-top: var(--spacing-60);
		padding-top: 0;
		//padding-bottom: var(--spacing-60);

		.row--reverse & {
			padding-left: 0;
		}
	}

	.btn {
		margin-top: 0.5rem;

		@include mq.mq($from: large) {
			margin-top: var(--spacing-30);
		}
	}
}


.c-imageText-item__image {

	.svg-trigger {
		text-align: center;
	}

	svg {
		width: 40rem;
		height: auto;
		max-width: 100%;
	}

	path:not(.fill) {
		fill: none;

		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		transition: all 4s ease-in-out;
		transition-delay: 1s;
	}

	.start path:not(.fill) {
		stroke-dashoffset: 0;
	}
}
