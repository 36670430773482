@use "../mixins/mq";


.c-teasers {
	.figure {
		margin-bottom: var(--spacing-40);
	}
}

.c-teasers__item-link {
	text-decoration: none;
	color: var(--color-white);

	&:hover {
		img {
			transform: scale(1.06);
		}
	}
}

.c-teasers__item-content {
	padding: 0 0 var(--spacing-20);
	color: var(--color-white);

	@include mq.mq($from: large) {
		padding-bottom:  var(--spacing-40);
	}

	.action-fab {
		width: 4rem;
	}

	* + .action-fab {
		margin-top: var(--spacing-10);

		@include mq.mq($from: large) {
			margin-top: var(--spacing-20);
		}
	}

	.c-teasers__item-title {
		margin-bottom: var(--spacing-10);

		@include mq.mq($from: large) {
			margin-bottom: var(--spacing-10);
		}
	}
}
