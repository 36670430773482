@use "sass:color";
@use "../_config/colors";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/mq";
@use "../atoms/grid";

.cookie-banner {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 2.5rem 2rem 2rem;
  width: 100vw;
  background-color: var(--color-black);
  color: var(--color-white);

  &__container {
    margin-inline: auto;
    max-width: var(--container-wdith);

    @include mq.mq($from: large) {
      display: flex;
      align-items: center;
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 3.6rem;
    margin-top: 2rem;

    @include mq.mq($from: large) {
      width: auto;
      flex-wrap: nowrap;
      flex-shrink: 0;
    }

  }

  &__settings {
    font-weight: 500;
    font-size: 1.7rem;
    text-decoration: underline;
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
      text-decoration: none;
    }
  }

  &__button {
    font-size: 1.7rem;
    padding: 1rem;
    background: none;
    border-radius: 4px;
    border: 1px solid var(--color-white);
    outline: none;
    box-shadow: none;

    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary-1);
    }
  }

  &__text {
    flex-grow: 1;
    @include mq.mq($from: large) {
      padding-right: 15%;
    }
  }

  &__title {
    @extend .h5;
    font-weight: 700;

    margin-bottom: 1rem;
  }
}


.cookie-choices {
  margin-top: 2rem;
}

.cookie-choice {
  --field-checkbox-margin-top: 0;
  --label-margin-top: 0;
  --label-color: var(--color-black);
  --label-align: left;

  position: relative;

  &__checkbox {
    font-size: 1.7rem;
    position: absolute;
    left: 0;
    top: 1.8rem;
    width: calc(100% - 3rem);
    background-color: var(--color-white);

    .field__sub-label {
      font-weight: 700;
    }

    .field__sub-label:before {
      transform: translateY(-0.5rem);
      border-color: var(--color-primary-900);

      @include mq.mq($from: large) {
        transform: translateY(-0.3rem);
      }
    }
  }

  &__details {
    border-bottom: 1px solid var(--color-grey-200);

    p:last-child {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    display: inline-flex;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    margin-right: 1.5rem;
    transform: translateY(-0.3rem);
    border-radius: 50%;
    background-color: var(--color-success);
    color: var(--color-white);
  }

  &__submit {
    margin-top: 2.4rem;

    @include mq.mq($from: large) {
      margin-top: 4rem;
    }
  }

  &__success {
    margin-top: 2rem;
    display: flex;
  }

  &__summary {
    list-style: none;
    height: 6.8rem;
    color: transparent;
    cursor: pointer;

    & + * {
      margin-top: 0;
    }

    &:before,
    &:after {
      position: absolute;
      right: 0;
      top: 3.4rem;
      display: block;
      content: '';
      width: 2.5rem;
      height: 0.3rem;
      background-color: var(--color-grey-200);
      border-radius: 3px;
    }

    &:after {
      transform: rotate(90deg);
      transition: transform var(--animation-duration) var(--animation-ease);

      .cookie-choice__details[open] > & {
        transform: rotate(0);
      }
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
}
