@use "../../mixins/mq";

.blog-categories-menu {
  padding-top: 25px;
  &__title {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 15px;

    @include mq.mq($from: large) {
      margin-bottom: 20px;
    }

  }

  &__nav {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 15px;
      flex-direction: column;
      @include mq.mq($from: large) {
        flex-direction: row;
        gap: 80px;
      }

    }

    &-item {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      @include mq.mq($from: large) {
        width: 25%;
      }


      &-link {
        display: flex;
        gap: 10px;
        align-items: center;
        @include mq.mq($from: large) {
          gap: 15px;
        }

        &-icon {
          display: flex;

          svg {
            width: 20px;
            height: 20px;
            @include mq.mq($from: large) {
              width: 50px;
              height: 50px;
            }
          }
        }

        &-title {
          color: #000000;
          font-size: 17px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 24px;
          transition: all .4s;
          @include mq.mq($from: large) {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .not-touch & {
        &:hover {
          .blog-categories-menu__nav-item-link-title {
            color: var(--color-red-hover);

          }
        }
      }

      &--active {
        .blog-categories-menu__nav-item-link-title {
          color: var(--color-red-hover);
          font-weight: bold;
        }

      }
    }
  }
}