@use "../_config/colors";
@use "../_config/fontSize-desktop";
@use "../_config/fontSize-mobile";
@use "../_config/spacing";
@use "../mixins/mq";

:root {
  --focalpoint: 50% 50%;
  --container-wdith: 160rem;

  --color-black: #{colors.$color-black};
  --color-grey-500:  #{colors.$color-grey-500};
  --color-grey-400:  #{colors.$color-grey-400};
  --color-grey-300:  #{colors.$color-grey-300};
  --color-grey-200:  #{colors.$color-grey-200};
  --color-grey-100:  #{colors.$color-grey-100};
  --color-white:  #{colors.$color-white};
  --color-primary-1:  #{colors.$color-primary-1};
  --color-primary-2:  #{colors.$color-primary-2};
  --color-primary-3:  #{colors.$color-primary-3};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-secondary-2:  #{colors.$color-secondary-2};
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};
  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};
  --color-red-hover:  #{colors.$color-red-hover};

  --spacing-200: #{spacing.$spacing-200};
  --spacing-140: #{spacing.$spacing-140};
  --spacing-120: #{spacing.$spacing-120};
  --spacing-100: #{spacing.$spacing-100};
  --spacing-80: #{spacing.$spacing-80};
  --spacing-60: #{spacing.$spacing-60};
  --spacing-50: #{spacing.$spacing-50};
  --spacing-40: #{spacing.$spacing-40};
  --spacing-30: #{spacing.$spacing-30};
  --spacing-20: #{spacing.$spacing-20};
  --spacing-10: #{spacing.$spacing-10};

  --font-size-200: 1.6rem;
  --font-size-300: 1.8rem;
  --font-size-400: 2rem;
  --font-size-600: 2.4rem;
  --font-size-700: 2.8rem;
  --font-size-800: 3.2rem;
  --font-size-900: 3.8rem;


  --global-font-size: 1.325rem;
  --global-line-height: 1.5;

  --font-size-button: 1.8rem;

  --button-primary-min-height: 6rem;
  --button-primary-min-width: 20rem;

  --bodyFontFamily: "Akkurat", Helvetica, Arial, sans-serif;
  --bodyFontColor: var(--color-black);

  --headerHeightSmall: 6rem;
  --headerHeight: 12rem;

  --hamburgerLineHeight: 1px;
  --hamburgerWidth: 30px;
  --hamburgerHeidht: 30px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);

  --paragraph-font-size: var(--font-size-400);


  @include mq.mq($from: large) {
    --font-size-200: 1.6rem;
    --font-size-300: 1.8rem;
    --font-size-400: 2rem;
    --font-size-600: 2.8rem;
    --font-size-700: 3.6rem;
    --font-size-800: 4.8rem;
    --font-size-900: 7.2rem;
  }
}

$bodyFontFamily: "Akkurat", Helvetica, Arial, sans-serif;
