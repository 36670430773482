@use "../mixins/mq";
@use "../_base/typography";

.modular .nav--main-nav {

	.nav__item--parent {
		position: relative;
		max-height: 6rem;
		width: 100%;
		margin: 0 var(--spacing-20);
		transition: max-height 0.5s var(--animationEase);
		overflow: hidden;

		@include mq.mq($from: large) {
			width: auto;
			max-height: 6rem;
			margin-right: 0;
			overflow: visible;

			&:not(:first-child) {
				margin-left: var(--spacing-30);
			}
		}

		@include mq.mq($from: xlarge) {
			&:not(:first-child) {
				margin-left: var(--spacing-60);
			}
		}
	}

	.nav__item--parent-opened {
		max-height: 200vh;

		@include mq.mq($from: large) {
			max-height: unset;
		}
	}

	.nav__link--parent {
		display: flex;
		height: 6rem;
		align-items: center;
		font-size: 2.4rem;
		line-height: 3.8rem;
		@extend .font-weight-700;
		color: var(--color-primary-1);
		border-bottom: 1px solid var(--color-primary-1);

		&.is-active {
			border-bottom-color: var(--color-primary-2);
		}

		@include mq.mq($from: medium) {
			font-size: 2rem;
		}

		@include mq.mq($from: large) {
			display: inline-flex;
			border-bottom: none;
		}

		@include mq.mq($from: xlarge) {
			font-size: 2.4rem;
		}
	}

	.is-active {
		color: var(--color-primary-2);
	}

	.font-weight-300 .nav__link--parent {
		@extend .font-weight-300;
	}

	.nav__link--parent {
		.not-touch & {
			&:hover {
				color: var(--color-primary-2);
			}
		}
	}
}

