@use "../mixins/mq";

.form-search {
  height: 8rem;
  display: none;
  visibility: hidden;
  padding: var(--spacing-20);
  background-color: var(--color-white);
  box-shadow: 0 2px 8px 0 #00000020;
  color: var(--color-grey-400);
  transform: translateY(0.6rem);
  max-width: 35rem;

  @include mq.mq($from: large) {
    position: absolute;
    left: 0;
    top: 100%;
    width: 35rem;
  }

  &--opened {
    display: block;
    visibility: visible;
  }
}
