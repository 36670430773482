@use "../mixins/mq";

.form-container {
	.modular & {
		margin: 0;

		.row::after {
			content: "";
			clear: both;
			display: table;
		}

		.row:last-child .form-group {
			margin-bottom: 0;
		}
	}

}

.form__title {
	text-align: center;
}

.form-text {
	margin-top: 0.3rem;
}
.form-group {
	> div[data-field-type='text'] {
		display: flex;
		flex-direction: column;
		> label {
		order: 1;
		}
		> input {
			order: 2;
		}
		> div {
			order: 3;
		}
	}
	> div[data-field-type='checkboxes'] {
		display: flex;
		flex-direction: column;
		input {
			margin-right: 0.5rem;
		}
	}
	> div[data-field-type='radios'] {
		display: flex;
		flex-direction: column;
		input {
			margin-right: 0.5rem;
		}
	}
	.form-check {
		> div[data-field-type='checkbox'] {
			display: flex;
			flex-direction: column;
			input {
				margin-right: 0.5rem;
			}
		}

	}
}

.form-check--formie {
	display: flex;
	flex-direction: row-reverse;
	justify-content: start;
	gap: 1rem;

	input {
		margin-top: 0;
		flex-shrink: 0;
	}
}

.form-success ~ div {

	margin-left: -11px;
	margin-right: -11px;
}