@use "../../mixins/mq";
.blog-teaser-related {
  &__title {
    font-size: 30px;
    line-height: 1.33333333333333;
    margin-bottom: 50px;

    font-weight: 300;
    letter-spacing: 0;

    @include mq.mq($from: medium) {
      font-size: 48px;

    }

  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @include mq.mq($from: medium) {
      display: flex;
      flex-direction: row;
      gap: 40px;
    }
  }


  &__image {
    overflow: hidden;

    figure {
      aspect-ratio: 1.33870968;
      img {
        width: 100%;
        aspect-ratio: 1.33870968;
        transition: transform .4s;
        object-fit: cover;
      }
    }



  }

  &__infos {
    padding-top: 15px;

    &-category {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        li {
          margin: 0;
          background-color: #F4F1E9;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          padding: 1.5px 9.5px 1.5px 7px;
          list-style: none;
          display: inline-block;
          font-weight: bold;
        }
      }
    }

    &-title {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 30px;
      margin-top: 12px;
      margin-bottom: 15px;
      transition: color .4s;
      color: #000000;

    }

    &-date {
      color: #474747;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__article {


    width: 100%;
    @include mq.mq($from: medium) {
      width: calc(100% / 3);
    }

    .not-touch & {
      &:hover {


        .blog-teaser-related__image {
          img {

            transform: scale(1.2);
          }
        }

        .blog-teaser-related__infos-title {
          color: var(--color-red-hover);
        }

      }
    }

  }
}