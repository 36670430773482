@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../_base/typography";

.modular .nav {
	display: inline-flex;
	max-width: 100%;

	ol, ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

.nav__toggle {
	position: absolute;
	top: 0;
	right: -1.5rem;
	width: 6rem;
	height: 6rem;
	transform-origin: center center;
	color: var(--color-primary-1);
	transition: transform var(--animationDuration) var(--animationEase);

	.is-active & {
		color: var(--color-primary-2);
	}

	.nav__item--parent-opened & {
		transform: rotate(45deg);
	}

	@include mq.mq($from: large) {
		display: none;
		visibility: hidden;
	}
}

.modular .nav__wrap {
	display: flex;
	flex-wrap: wrap;

	&--parent {
		.nav--main-nav &,
		.nav--meta-nav & {
			@include mq.mq($from: large) {
				justify-content: flex-end;
			}
		}
	}

	&--child {
		padding-bottom: var(--spacing-60);

		@include mq.mq($from: large) {
			position: fixed;
			left: 0;
			top: 0;
			min-width: 50rem;
			max-width: 100vw;
			max-height: 50vh;
			overflow: scroll;
			display: none;
			opacity: 0;
			flex-wrap: nowrap;
			padding: 5rem 10rem 5.5rem;
			background-color: var(--color-primary-3);
			transform: translate3d(0, 0, 0);
			white-space: nowrap;
			box-shadow: 0 2px 10px 0 #00000020;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */

			&::-webkit-scrollbar {
				display: none;
			}

			&:before {
				position: absolute;
				left: 0;

				top: 0;
				display: block;
				width: 100%;
				height: 40px;
				content: '';
				box-shadow: inset 0 30px 20px -30px rgba(0,0,0,0.20)
			}
		}

		@include mq.mq($from: xxlarge) {
			flex-wrap: nowrap;
		}
	}

	&.nav__wrap--visible {
		//display: flex;
		display: grid;
		gap: 6rem;

		z-index: 9998;
		visibility: visible;
	}

	&.nav__wrap--visible-overflow {
		left: auto !important;
	}
}

.nav__wrap--2 {
	grid-template-columns: repeat(2, 1fr);
}

.nav__wrap--3 {
	grid-template-columns: repeat(2, 1fr);

	@include mq.mq($from: xxlarge) {
		grid-template-columns: repeat(3, 1fr);
	}
}

.nav__wrap--4 {
	grid-template-columns: repeat(2, 1fr);

	@include mq.mq($from: xxlarge) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.nav__item {
	position: relative;

	.icon-chevron-down,
	.icon-chevron-down svg {
		@include mq.mq($from: large) {
			width: 20px;
			height: 12px;
		}
	}
}

.nav__link {
	text-decoration: none;
	color: var(--color-grey-400);

	&.is-active {
		color: var(--color-primary-2);
	}
}

.modular .nav .nav__item--child {
	display: flex;
	align-items: flex-end;
	width: 100%;
	margin-top: 1.8rem;
	padding-right: var(--spacing-50);
	padding-bottom: 0.6rem;
	border-bottom: 1px solid var(--color-grey-100);

	@include mq.mq($from: large) {
		margin-top: 1rem;
		min-height: 3.6rem;
		padding-bottom: 0.4rem;

		&:first-child {
			margin-top: 0;
		}
	}
}

.modular .nav .nav__item--child-empty {
	display: none;
	margin: 0;
	height: 0;
	border: 0;

	@include mq.mq($from: large) {
		display: block;
	}
}

.nav__link--child {
	white-space: initial;
	font-size: 1.8rem;
	line-height: 2.4rem;
	@extend .font-weight-400;
	color: var(--color-grey-400);

	&:hover {
		color: var(--color-primary-2);
	}
}

.modular .nav .nav__wrap--child-inner {
	width: 100%;

	@include mq.mq($from: large) {
		display: grid;
		grid-auto-rows: 1fr;
		width: 50rem;
		max-width: calc((100vw - 200px - 60px) / 2 );

		/*margin-bottom: 5.5rem;
		margin-left: var(--spacing-60);

		&:nth-child(2n + 1) {
			margin-left: 0;
		}
*/

		@include mq.mq($from: xlarge) {
			max-width: calc((100vw - 200px - 2 * 60px) / 3 );
/*
			&:nth-child(2n + 1) {
				margin-left: var(--spacing-60);
			}

			&:nth-child(3n + 1) {
				margin-left: 0;
			}*/
		}

		@include mq.mq($from: xxlarge) {

			width: 35.5rem;
			max-width: calc((100vw - 3 * 60px) / 4 );
			/*//margin-left: var(--spacing-60);

			&:nth-child(3n + 1) {
				margin-left: var(--spacing-60);
			}

			&:nth-child(4n + 1) {
				margin-left: 0;
			}*/
		}
	}
}
