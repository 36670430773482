// Fixes from former template
@use "mixins/mq";

.slick-list {
  background-color: var(--color-primary-1);

  @include mq.mq($from: large) {
    background-color: unset;
  }
}


.slide__figure {
  img {
    object-position: var(--focalpoint);
    height: 100%;
  }
}

.offer-overview-tile {
  img {
    width: 100%;
  }
}
