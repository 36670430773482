@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--global-font-size);

	background-color: var(--color-white);
	hyphens: auto;
	font-variant: none;

	//background-color: black;

	word-break: break-word;

	padding-top: 0;
	/*padding-top: var(--headerHeightSmall);

	@include mq.mq($from: large) {
		padding-top: var(--headerHeight);
	}*/
}

a:focus {
	outline: none;
}
