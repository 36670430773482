@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "svg";


.modular .font-size-200,
.font-size-200 {
  font-size: var(--font-size-200); // 1.6rem
  line-height: 2.4rem;
}

.modular .font-size-300,
.font-size-300 {
  font-size: var(--font-size-300); // 1.8rem
  line-height: 2.7rem;
}

.modular .font-size-400,
.font-size-400 {
  font-size: var(--font-size-400); // 2rem
  line-height: 3rem;
}

.modular .font-size-600,
.font-size-600 {
  @extend .font-weight-300;
  font-size: var(--font-size-600); // 2.4rem / 2.8rem
  line-height: 3.4rem;

  @include mq.mq($from: large) {
    line-height: 4.2rem;
  }
}

.modular .font-size-700,
.font-size-700 {
  @extend .font-weight-300;
  font-size: var(--font-size-700); // 2.6rem / 3.6rem
  line-height: 3.8rem;

  @include mq.mq($from: large) {
    line-height: 4.8rem;
  }
}

.modular .font-size-800,
.font-size-800 {
  @extend .font-weight-300;
  font-size: var(--font-size-800); // 3.2rem / 4.8rem
  line-height: 4.2rem;

  @include mq.mq($from: large) {
    line-height: 6.4rem;
  }
}

.modular .font-size-900,
.font-size-900 {
  font-size: var(--font-size-900); // 7.2rem
  line-height: 4.8rem;

  @include mq.mq($from: large) {
    line-height: 9rem;
  }

}

strong,
b,
.font-weight-700,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.modular {

  p {
    margin-bottom: 0;
    font-size: var(--paragraph-font-size);
  }

  h1, .h1 {
    margin: unset;
    @extend .font-size-900;
  }

  h2, .h2 {
    margin: unset;
    @extend .font-size-800;
  }

  h3, .h3 {
    margin: unset;
    @extend .font-size-700;
  }

  h4, .h4 {
    margin: unset;
    @extend .font-size-600;
  }

  h5, .h5 {
    margin: unset;
    @extend .font-size-300
  }

  h6, .h6 {
    margin: unset;
    @extend .font-size-200
  }

  .lead {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;

    @include mq.mq($from: medium) {
      font-size: 24px;
    }
  }
}

.font-color-primary {
  color: var(--color-primary-1);
}

.font-color-white {
  color: var(--color-white);
}

.modular blockquote {
  margin-bottom: 0;
  @extend .font-size-700;
  color: var(--color-primary-1);
  quotes: "«" "»";

  &:before {
    content: open-quote;

    .lang-fr-ch & {
      margin-right: 0.5rem;
    }
  }

  &:after {
    content: close-quote;

    .lang-fr-ch & {
      margin-left: 0.5rem;
    }
  }
}

.modular .styled {

  a:not(.btn):not(.action) {
    &:hover {
      text-decoration: underline;
    }
  }

  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  * + figure:not(.iframe__poster),
  * + .font-size-500,
  * + .font-size-600,
  * + .font-size-700 {
    margin-top: var(--spacing-30);
  }

  * + .font-size-800 {
    margin-top: var(--spacing-50);
  }

  * + .font-size-900 {
    margin-top: var(--spacing-60);
  }


  .font-size-500,
  .font-size-600,
  .font-size-700,
  .font-size-800,
  .font-size-900 {
    & + p {
      margin-top: var(--spacing-20);
    }
  }

  * + .font-size-900  {
    margin-top: var(--spacing-80);
  }

  .font-size-400 + .font-size-400 {
    margin-top: var(--spacing-20);
  }

  .font-size-900 + * {
    margin-top: var(--spacing-40);
  }

  * + .iframe-wrapper {
    margin-top: var(--spacing-40);
  }

  figcaption {
    padding-top: var(--spacing-20);
    text-align: left !important;
    @extend .font-size-300;
  }

  .iframe-wrapper + *,
  figure:not(.iframe__poster) + * {
    margin-top: 5rem;

    @include mq.mq($from: large) {
      margin-top: var(--spacing-80);
    }
  }

  * + blockquote {
    margin-top: 5rem;

    @include mq.mq($from: large) {
      margin-top: var(--spacing-120);
    }
  }

  ul, ol {
    font-size: var(--font-size-400); // 2rem
    line-height: 3rem;

    & + * {
      margin-top: var(--spacing-30);

      @include mq.mq($from: large) {
        margin-top: var(--spacing-40);
      }
    }

    li {
      position: relative;
      padding-left: 3.5rem;
      counter-increment: counter-inc;

      &:not(:first-child) {
        margin-top: var(--spacing-10);
      }
    }
  }

  ul {
    li:before {
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-flex;
      width: 2rem;
      content: "–";
      font-size: 1.4rem;
      color: var(--color-primary-1);
    }
  }

  ol {
    li:before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-flex;
      content: counter(counter-inc) ". ";
      width: 3rem;
      color: var(--color-primary-1);
      font-size: inherit;
      @extend .font-weight-700;
    }
  }

  * + ul,
  * + ol {
    margin-top: var(--spacing-20);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-20);
    }
  }
}


.color--red {
  color: rgb(154, 8, 65);
}

.c-text {
  .image {
    display: flex;
    align-items: center;
    flex-direction: column;

    figcaption {
      align-self: flex-start;
    }
  }
}