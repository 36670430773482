@use "../_base/grid";
@use "../mixins/mq";

$sizes: (
		'sm',
		'md',
		'lg'
);

.modular .container {
	width: 100%;
	max-width: var(--container-wdith);
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
	padding-right: 0;
}
.container--fullwidth {
	width: 100%;
}
.container-blog {
	width: 100%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
	padding-right: 0;
	&--content {
		min-height: 25rem;
	}
}

.row {
	.modular & {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-left: 0;
		margin-right: 0;
		width: 100%;

		&:before,
		&:after {
			display: none;
		}
	}

	.legacy & {
		display: flex;
		width: auto;
		margin-left: -11px;
		margin-right: -11px;
	}
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

.row--fullmobile {
	@include mq.mq($until: medium) {
		margin-left: -3rem;
		margin-right: -3rem;
	}
}

.modular .row--grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 10px;
	justify-content: center;
	grid-template-rows: auto;
	align-items: start;

	@include mq.mq($from: medium) {
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 2rem;
	}

	@include mq.mq($from: large) {
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 4rem;
	}
}

.modular .row--reverse {
	@include mq.mq($from: medium) {
		direction: rtl;
	}

	* {
		direction: ltr;
		text-align: left;
	}
}

.col {
	.modular & {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		float: none;
	}
}

.col--half {
	width: 50%;
}

.wrapper {
	//position: relative;
	width: 100%;
	padding-left: 3rem;
	padding-right: 3rem;

	@include mq.mq($from: large) {
		padding-left: 4rem;
		padding-right: 4rem;
	}

}

.wrapper--sm-full {

	padding-left: 0;
	padding-right: 0;

	@include mq.mq($from: large) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

.align-center {
	.modular & {
		align-items: center;
	}
}

.middle {
	margin-left: auto;
	margin-right: auto;
}

.middle--medium {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 77rem;
	}
}

.middle--large {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 94rem;
	}
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

	@if ($breakpointStart) {

		@include mq.mq($from: $breakpointStart, $until: $breakpointEnd) {

			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

			}
		}

	} @else {

		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}

		}
	}
}

@each $size in $sizes {

	@if $size == 'sm' {
		@include generateColumns(grid.$columns, $size);
	}

	@else if $size == 'md' {
		@include generateColumns(grid.$columns, $size, medium, large);
	}

	@else if $size == 'lg' {
		@include generateColumns(grid.$columns, $size, large);
	}
}
