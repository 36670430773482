@use "../mixins/mq";

.icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	font-style: normal;
	background-size: 100% 100%;
	width: var(--icon-width);

	svg {
		width: 100%;
		height: auto;
	}
}

$icons: (
		('chevron-down', 22, 22, 22, 22, 22, 22),
		('chevron-right', 6, 11, 6, 11, 6, 11),
		('home', 30, 30, 30, 30, 30, 30),
		('glass', 26, 26, 26, 26, 26, 26),
		('play', 20, 20, 20, 20, 36, 40),
		('plus', 20, 20, 20, 20, 20, 20),
		('blogwhatsapp', 25, 25, 25, 25, 25, 25),
		('blogfacebook', 25, 25, 25, 25, 25, 25),
		('blogtwitter', 25, 25, 25, 25, 25, 25),
		('blogxing', 25, 25, 25, 25, 25, 25),
		('blogshare', 25, 25, 25, 25, 25, 25),
);


@each $name, $widthSmall, $heightSmall, $widthMedium, $heightMedium, $widthLarge, $heightLarge in $icons {
	.icon-#{$name},
	.icon-#{$name} svg {
		width: #{$heightSmall}px;
		height: #{$heightSmall}px;

		@include mq.mq($from: medium) {
			width: #{$widthMedium}px;
			height: #{$heightMedium}px;
		}

		@include mq.mq($from: large) {
			width: #{$widthLarge}px;
			height: #{$heightLarge}px;
		}
	}
}
