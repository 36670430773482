@use "blog/blog-entry-heading";
@use "blog/blog-related-entries";
@use "blog/blog-related-tags";
@use "blog/blog-categories-menu";
@use "blog/blog-category";

@use "../mixins/mq";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blog {
  &-entry {
    padding-bottom: 225px;
  }

  &-card {
    display: block;
    @include mq.mq($from: large) {
      display: flex;
      flex-direction: column;
      gap: 20px;

    }

    &-list {

      @include mq.mq($from: large) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }


    }

    &__image {
      width: 100%;
      margin-bottom: 15px;
      overflow: hidden;
      @include mq.mq($from: large) {
        width: 100%;
        margin-bottom: 0;
      }

      .figure {
        aspect-ratio: 1.3333333333;

        img {
          width: 100%;
          aspect-ratio: 1.3333333333;
          transition: transform .4s;
          object-fit: cover;
        }
      }


    }

    &__infos {
      flex: 1;
      min-width: 50%;

      &-category {
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          li {
            margin: 0;
            background-color: #F4F1E9;
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            padding: 1.5px 9.5px 1.5px 7px;
            list-style: none;
            display: inline-block;
            font-weight: bold;

          }
        }
      }

      &-title {
        color: #000000;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 30px;
        margin: 10px 0 10px 0;
        transition: color .4s;
        @include mq.mq($from: large) {
          font-size: 18px;
          line-height: 27px;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      &-introduction {
        color: #000000;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 27px;
        margin-bottom: 10px;
        @include mq.mq($from: large) {
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 20px;
        }

      }

      &-date {
        color: #474747;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    .not-touch & {
      &:hover {
        .blog-card__image {

          img {
            transform: scale(1.2);
          }
        }

        .blog-card__infos-title {
          color: var(--color-red-hover);
        }
      }
    }

    &--big {
      position: relative;
      padding-bottom: 50px;

      @include mq.mq($from: large) {
        padding-bottom: 0;
      }

      .blog-card__image {
        width: 100%;
        overflow: hidden;

        figure {
          aspect-ratio: 1.1713555;

          img {
            width: 100%;
            aspect-ratio: 1.1713555;
            transition: transform .4s;
            object-fit: cover;
          }
        }

      }

      .blog-card__infos {

        padding-top: 15px;

        @include mq.mq($from: large) {
          position: absolute;
          bottom: 0;
          width: 74.454148%;
          padding-top: 24px;
          padding-right: 39px;
          background: #ffffff;
        }

        .blog-card__infos-category {
          ul {
            margin: 0;
            padding: 0;

            li {
              margin: 0;
              background-color: #F4F1E9;
              color: #000000;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 20px;
              padding: 1.5px 9.5px 1.5px 7px;
              list-style: none;
              display: inline-block;

            }
          }
        }

        .blog-card__infos-title {
          color: #000000;
          font-size: 20px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 30px;
          margin-top: 10px;
          margin-bottom: 10px;
          transition: color .4s;

          @include mq.mq($from: large) {
            font-size: 28px;
            line-height: 42px;
            margin-bottom: 20px;
          }

        }

        .blog-card__infos-date {
          color: #474747;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 20px;
        }
      }

      .not-touch & {
        &:hover {
          .blog-card__image {
            img {
              transform: scale(1.2);
            }
          }

          .blog-card__infos-title {
            color: var(--color-red-hover);
          }
        }
      }
    }

    &-small-list {

      @include mq.mq($from: large) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }

    &--small {

      @include mq.mq($from: large) {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding-bottom: 0;
      }

      .blog-card__image {
        margin-bottom: 16px;
        @include mq.mq($from: large) {
          width: 234px;
          margin-bottom: 0;
        }

        figure {

          aspect-ratio: 1;

          img {
            aspect-ratio: 1;
          }
        }

      }

      .blog-card__infos {
        padding-top: 0;
        flex: 1;


        .blog-card__infos-category {
          ul {

            li {

            }
          }
        }

        .blog-card__infos-title {
          font-size: 23px;
          line-height: 33px;
          margin: 10px 0 20px 0;
        }

        .blog-card__infos-date {
        }
      }

      .not-touch & {
        &:hover {
          .blog-card-small__image {

            img {
              transform: scale(1.2);
            }
          }

          .blog-card-small__infos-title {
            color: var(--color-red-hover);
          }
        }
      }
    }

    &--large {
      gap: 0;
      margin-bottom: 50px;
      @include mq.mq($from: large) {
        gap: 0;
      }

      .blog-card__image {
        margin-bottom: 16px;

        @include mq.mq($from: large) {
          width: 100%;
          margin-bottom: 0;
        }

        figure {


          @include mq.mq($from: large) {
            aspect-ratio: 1.74375821;
          }

          img {
            @include mq.mq($from: large) {
              aspect-ratio: 1.74375821;
            }

          }
        }

      }

      .blog-card__infos {

        @include mq.mq($from: large) {
          flex: 1;
          min-width: 50%;
          background: #ffffff;
          width: 64.807837%;
          padding: 40px 40px 0 40px;

          margin-top: -150px;
          min-height: 150px;
          margin-left: 97px;
          z-index: 1;
        }

        .blog-card__infos-category {
          ul {

            li {

            }
          }
        }

        .blog-card__infos-title {


          font-size: 24px;
          line-height: 34px;

          @include mq.mq($from: large) {
            font-size: 23px;
            line-height: 33px;
            margin: 10px 0 20px 0;
          }
        }

        .blog-card__infos-introduction {

        }

        .blog-card__infos-date {
        }
      }

      .not-touch & {
        &:hover {
          .blog-card-small__image {

            img {
              transform: scale(1.2);
            }
          }

          .blog-card-small__infos-title {
            color: var(--color-red-hover);
          }
        }
      }
    }


  }

  &-list {
    padding-bottom: 50px;

    &__loadmore {
      text-align: center;
      color: #9A0941;
      padding-top: 25px;
      display: flex;
      justify-content: center;

      @include mq.mq($from: large) {
        padding-top: 50px;
      }

      .icon-loadmore {
        display: none;
        width: 50px;
        height: 50px;
        animation: rotation 1s linear infinite;

      }

      &--active {

        margin-bottom: 100px;
        .icon-loadmore {
          display: block;
        }
      }
    }
  }
}

h1.blog-teaser__title {
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  @include mq.mq($from: large) {
    margin-bottom: 50px;
    font-size: 48px;
    line-height: 64px;
  }

}

.blog-teaser__all-button {
  margin-top: 50px;
}


.blog-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0;
  row-gap: 50px;
  @include mq.mq($from: large) {
    column-gap: 40px;
    row-gap: 100px;
  }

  > .blog-card {

    width: 100%;
    @include mq.mq($from: large) {
      width: calc(calc(100% - 80px) / 3);
    }

    .blog-card__infos-title {
      @include mq.mq($from: large) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  > :nth-child(1),
  > :nth-child(2),
  > :nth-child(6),
  > :nth-child(7),
  > :nth-child(10n+1),
  > :nth-child(10n+2),
  > :nth-child(10n+6),
  > :nth-child(10n+7) {

    width: 100%;
    @include mq.mq($from: large) {
      width: calc(calc(100% - 40px) / 2);
    }
  }

}

.blog-list-mini {
  display: flex;
  flex-direction: column;
  gap: 50px;
  @include mq.mq($from: large) {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 100px 0;
  }

  .blog-card {

    @include mq.mq($from: large) {
      width: calc(100% / 3);
      display: flex;
      flex-direction: row;
      gap: 15px;
    }

    &__image {

      @include mq.mq($from: large) {
        width: 150px;
      }

      figure {

        @include mq.mq($from: large) {
          aspect-ratio: 1;
        }

        img {
          @include mq.mq($from: large) {
            aspect-ratio: 1;
          }
        }
      }
    }

  }
}

.c-blogTeaser .blog-card--small {
  margin-top: 50px;
  @include mq.mq($from: large) {
    margin-top: 0;
  }
}