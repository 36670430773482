@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";

.footer-main {
  display: block;
}

.footer-main-content  {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 3rem;
  @extend .font-weight-400;

  @include mq.mq($from: medium) {
    margin-left: 0;
    margin-right: 0;
  }
}

.nav-footer {
  float: none;
}

.footer__brand {
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  gap: 2rem;


  a {
    color: var(--color-grey-400);

    .not-touch & {
      &:hover {
        color: var(--color-primary-1);
      }
    }

  }
}
