@use "../../mixins/mq";
.blog-related-tags {
  margin-top: 20px;
  margin-bottom: 150px;
  &__title {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;

  }

  &__list {
    display: flex;
    gap: 40px;
    margin: 0;
    padding: 0;

    flex-wrap: wrap;
    justify-content: center;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      font-size: 28px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 42px;
      background-color: #F4F1E9;
      padding: 5px 10px;
      a {
        color: var(--color-black);
        &:hover {
          color: var(--color-primary-1);
        }
      }
    }
  }
}