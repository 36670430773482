@use "../mixins/mq";

.c-heading {

	.section {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 90vh;
		padding-top: var(--spacing-30);
		padding-bottom: var(--spacing-30);

		@include mq.mq($from: large) {
			padding-top: var(--spacing-60);
			padding-bottom: 5.5rem;
		}
	}

	.figure {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.styled h1,
	.styled .h1 {
		& + h2,
		& + .h2,
		& + h3,
		& + .h3 {
			margin-top: var(--spacing-10);
		}
	}

	.btn-cta:hover {
		background-color: var(--color-white);
	}
	&--search {
		margin-bottom: 23.7rem;

		@include mq.mq($from: large, $until: wide) {
			margin-bottom: 32rem;
		}
		.section {
			height: 62.7rem;
			min-height: initial;
			overflow: unset;
			figure {
				/*background: red;*/
			}
		}
	}
}
