@use "../../mixins/mq";

.blog-entry-heading {
  --icon-width: 3.5rem;

  @include mq.mq($from: large) {
    --icon-width: 2.5rem;
  }

  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #F2F4F9;
  }

  &--image {
    &:before {
      bottom: 50%;
    }
  }

  &--first {
    margin-top: 50px;
  }

  &__infos {
    padding-top: 50px;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      margin-bottom: 15px;
      @include mq.mq($until: medium) {
        flex-direction: row;

      }

      li {
        list-style: none;

        color: #474747;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 27px;
        text-align: center;
        margin-right: 4px;

        &:after {
          content: '|';
          margin-left: 2px;
          margin-left: 4px;
          @include mq.mq($until: medium) {
            display: none;
          }
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        &.blog-entry-heading__infos-date {

          @include mq.mq($until: medium) {
            text-align: left;
            white-space: nowrap;
            width: 33%;

          }
        }
        &.blog-entry-heading__infos-time {
          @include mq.mq($until: medium) {
            text-align: left;

          }
        }
        &.blog-entry-heading__infos-author {
          @include mq.mq($until: medium) {
            text-align: left;
            width: 67%;
          }
        }
      }
    }

    &--newline {
      ul {
        @include mq.mq($until: medium) {
          flex-direction: column;
        }

        li.blog-entry-heading__infos-author, li.blog-entry-heading__infos-date  {
          @include mq.mq($until: medium) {
            width: 100%;

          }
        }
      }
    }
  }

  &__title {

    font-size: 30px;
    line-height: 1.33333333333333;
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 50px;

    @include mq.mq($from: medium) {
      font-size: 48px;
      line-height: 64px;
    }

  }

  &__image {
    &:before {
      content: '';
      background: red;
      left: 0;
      right: 0;
      top: 50%;
      bottom: 0;
      width: 100vw;
    }

    .figure-blogcard {
      picture {
        position: relative;

        img {
          position: relative;
          width: 100%;
          aspect-ratio: 2.04153846;
        }
      }
    }
  }

  &__newsletter {
    margin-top: 50px;
    cursor: pointer;
    display: flex;
    height: 50px;
    align-items: center;
    gap: 13px;

    &-icon {

      img {
        width: 50px;
        height: 50px;
      }
    }

    &-text {
      color: #9A0841;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      transition: transform .4s;
    }

    &:hover {
      .blog-entry-heading__newsletter-text {
        color: #B3BA12;
      }
    }

  }

  &__introduction {
    margin-top: 100px;
    color: #000000;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 42px;

    margin-bottom: 0;
  }

  &__socials {

    display: none;
    position: relative;

    @include mq.mq($from: large) {
      display: block;
    }

    ul {
      position: absolute;
      left: 0;
      top: 150px;
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        .icon {
          transition: all .4s;
          color: #808181;
        }

        &:hover {
          .icon {
            color: #9A0941;
          }
        }

        .share__button {
          border: none;
          background: transparent;
          padding: 0;
          position: relative;

          &--active {
            .share__button-tooltip {
              visibility: visible;

              opacity: 1;
            }
          }

          &-tooltip {
            visibility: hidden;
            opacity: 0;
            width: 12rem;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 0.5rem;
            border-radius: 0.6rem;

            top: -1.25rem;
            left: 3.5rem;
            position: absolute;
            z-index: 1;
            transition: opacity var(--animationDuration) var(--animationEase);


            &:after {
              content: " ";
              position: absolute;
              top: 50%;
              right: 100%; /* To the left of the tooltip */
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent black transparent transparent;
            }
          }
        }
      }
    }

    &-mobile {
      --social-nav-margin: 5rem 0 0 0;

      display: block;

      @include mq.mq($from: large) {
        display: none;
      }
    }


    &-horizontal {


      ul {
        margin: var(--social-nav-margin);
        padding: 0;
        display: inline-flex;
        gap: 2rem;
      }

      li {
        list-style: none;

        &:first-of-type {
          margin-left: 0;
        }

        .icon {
          transition: all .4s;
          color: #808181;

          width: 35px;
          height: 35px;

          svg {
            width: 35px;
            height: 35px;
          }
        }

        &:hover {
          .icon {
            color: #9A0941;
          }
        }

        .share__button {
          border: none;
          background: transparent;
          padding: 0;
          position: relative;

          .share__button-tooltip {
            visibility: hidden;
            opacity: 0;
            width: 12rem;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 0.5rem;
            border-radius: 0.6rem;

            left: -4.25rem;
            top: 4.5rem;
            position: absolute;
            z-index: 1;
            transition: opacity var(--animationDuration) var(--animationEase);


            &:after {
              content: " ";
              position: absolute;
              bottom: 100%;  /* At the top of the tooltip */
              left: 50%;
              margin-left: -0.5rem;
              border-width: 0.5rem;
              border-style: solid;
              border-color: transparent transparent black transparent;
            }
          }

          &--active {
            .share__button-tooltip {
              visibility: visible;

              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.container-blog {
  .modular {
    .wrapper {
      padding: 0;
    }
  }
}

.blog-entry--category-medizinische-fachbereiche,
.blog-entry--category-domaines-medicaux {
  .blog-entry-heading {
    &:before {
      background-color: #FFFAEB;
    }
  }
}

.blog-entry--category-software-entwicklung-digitale-tools,
.blog-entry--category-developpement-de-logiciels-outils-numeriques
{
  .blog-entry-heading {
    &:before {
      background-color: #F2F4F9;
    }
  }
}

.blog-entry--category-gesundheits-und-case-management,
.blog-entry--category-gestion-sante-case-management {
  .blog-entry-heading {
    &:before {
      background-color: #FBEEF3;
    }
  }
}

.blog-entry--category-diversity-inclusion,
.blog-entry--category-diversite-inclusion
{
  .blog-entry-heading {
    &:before {
      background-color: #F2F2E0;
    }
  }
}