@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

.figure {
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 100%;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
	}

	&-three {
		aspect-ratio: 17/14;
	}

	&-two {
		aspect-ratio: 39/29;
	}

	&-fullscreen:after {
		top: auto;
		bottom: 0;
		height: 100%;
		background: linear-gradient(to bottom, #00000000, #000000);
		opacity: 0.7;

		@include mq.mq($from: large) {
			height: 60%;
		}
	}
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}


.figure-company-image {
	aspect-ratio: 38/40;
}

.figure-cta {
	position: absolute;
	top: 0;
	left: 50%;
	width: 12rem;
	height: 12rem;
	border-radius: 50%;
	overflow: hidden;
	transform: translate3d(-50%, -50%, 0);

	@include mq.mq($from: large) {
		width: 16rem;
		height: 16rem;

	}
}
