@use "../mixins/mq";

.modular {
	.section {
		position: relative;
		overflow: hidden;
		padding: 0;
	}

	.section--background-primary {
		background-color: var(--color-primary-1);
		color: var(--color-white);
	}

	.section--spacing {
		padding-top: var(--spacing-40);
		padding-bottom: var(--spacing-40);

		@include mq.mq($from: large) {
			padding-top: var(--spacing-80);
			padding-bottom: var(--spacing-80);
		}
	}


	.section--spacing-top {
		padding-top: var(--spacing-40);

		@include mq.mq($from: large) {
			padding-top: var(--spacing-80);
		}
	}
}

.section__title {
	.modular & {
		margin-bottom: var(--spacing-40);

		@include mq.mq($from: large) {
			margin-bottom: var(--spacing-60);
		}
	}
}
