@use "../mixins/mq";
@use "../_base/typography";

.modular .nav--footer-nav {
	margin-top: var(--spacing-30);
	width: 100%;

	@include mq.mq($from: large) {
		width: auto;
	}

	.nav__wrap {
		flex-direction: column;

		@include mq.mq($from: large) {
			flex-direction: row;
		}
	}

	.nav__item {
		margin-right: var(--spacing-30);

		&:not(:first-child) {
			margin-top: var(--spacing-20);
		}

		@include mq.mq($from: large) {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	.nav__link {
		font-size: 1.6rem;
		line-height: 2.3rem;
		@extend .font-weight-400;
		color: var(--color-grey-400);

		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}

		&.is-active {
			color: var(--color-primary-1);
		}
	}
}
