@use "../mixins/mq";
@use "../atoms/grid";

.c-cta {
	--social-nav-margin: 5rem auto 0;

	.btn {
		margin: var(--spacing-10) var(--spacing-20);
	}

	&__links {
		margin-top: var(--spacing-30);

		@include mq.mq($from: large) {
			margin-top: var(--spacing-50);
		}
	}

	&__content {
		position: relative;
		padding: var(--spacing-40);
		background-color: var(--color-primary-3);

		@include mq.mq($from: large) {
			padding: var(--spacing-60);
		}
	}

	&__text {
		margin-top: 2.5rem;
	}

	&__title + .c-cta__subtitle {
		margin-top: var(--spacing-20);

		@include mq.mq($from: medium) {
			margin-top: 0;
		}
	}
}

.c-cta__content--is-image {
	margin-top: var(--spacing-80);
	padding-top: var(--spacing-100);
}

.c-cta .text-align-left .c-cta__links .action {
	margin-left: 0;
}

.container-blog {
	.c-cta__content {

		@include mq.mq($until: large) {
			padding: 8rem 2rem;
		}
	}
}