@use "../../mixins/mq";

.blog-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  padding-top: 17px;
  margin-top: 25px;
  text-align: center;
  @include mq.mq($from: large) {
    padding-bottom: 59px;
    padding-top: 31px;
    margin-top: 50px;
  }

  &__title {
    font-size: 38px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 48px;


    text-align: center;
    margin-bottom: 13px;
    @include mq.mq($from: large) {
      font-size: 72px;
      line-height: 90px;
      margin-bottom: 40px;
    }
  }

  &--none {
    .blog-category__title {
      color: #9A0941;
    }
  }
  &__tags {
    padding-left: 3rem;
    padding-right: 3rem;
    @include mq.mq($from: large) {
      padding: 0;
    }
    .blog-category__list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      max-width: 730px;
      justify-content: center;
      row-gap: 16px;
      column-gap: 20px;


      max-height: 92px;
      overflow: hidden;
      @include mq.mq($from: large) {
        row-gap: 20px;
        max-height: 86px;
      }

      &--showall {
        max-height: none;
      }
      .blog-category__tag {
        list-style: none;
        padding: 0;

        cursor: pointer;
        background: blue;
        font-weight: bold;
        @include mq.mq($from: large) {

        }
        &:hover {
          color: #9A0941;
        }
        span {

          font-size: 17px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 27px;
          padding: 5.5px 9.5px 5.5px 7px;
          background-color: #F4F1E9;
          display: inline-block;
          @include mq.mq($from: large) {
            font-size: 20px;
            line-height: 22px;
          }
        }
        &--active {

          span {
            background-color: #9A0941;
            color: #FFFFFF;
          }
        }
      }
    }
  }


  &__showall {
    margin-top: 20px;
    border: none;
    background: transparent;
    padding: 0;
    font-weight: bold;

    font-size: 20px;
    line-height: 1.5;
    color: #9A0941;
    span {
      text-decoration: underline;

    }


    &:hover {
      color: #B3BA12;
    }
    .blog-category__showall-more {
      display: inline-block;
    }
    .blog-category__showall-less {
      display: none;
    }
    &--less {
      .blog-category__showall-more {
        display: none;
      }
      .blog-category__showall-less {
        display: inline-block;
      }
    }
  }
  &--yellow {
    background-color: #FFFAEB;
    .blog-category__tags ul .blog-category__tag:not(.blog-category__tag--active) span {
      background: #ffffff;
    }
  }
  &--red {
    background-color: #FBEEF3;
    .blog-category__tags ul .blog-category__tag:not(.blog-category__tag--active) span {
      background: #ffffff;
    }
  }
  &--green {
    background-color: #F2F2E0;
    .blog-category__tags ul .blog-category__tag:not(.blog-category__tag--active) span {
      background: #ffffff;
    }
  }
  &--grey {
    background-color: #F2F4F9;
    .blog-category__tags ul .blog-category__tag:not(.blog-category__tag--active) span {
      background: #ffffff;
    }
  }
}