@use "../mixins/mq";

.modular .nav--social-nav {

	.nav__item:not(:first-child) {
		margin-left: 4rem;

		@include mq.mq($from: large) {
			margin-left: 2.5rem;
		}
	}

	.nav__link {
		color: var(--white);
	}

	.icon,
	svg {
		width: 20px;
		height: 20px;
	}

	.not-touch & {
		.nav__link:hover {
			color: var(--color-primary-1);
		}
	}
}
